import { takeEvery, call, put, fork } from "redux-saga/effects";
import * as actions from "../actions/pipelines";
import * as events from "../events";

function* addProperty(action) {
  try {
    const {data:property, pipeline} = action.data;
    const result = yield call(actions.addPropertySaga, action.data);
    if (result) {
      yield put(
        actions.addPropertySagaPut({
          pipeline,
          property,
        })
      );
    }
  } catch (error) {
    console.error(error);
  }
}

function* removeProperty(action) {
  try {
    const {property, pipeline} = action.data;
    const result = yield call(actions.removePropertySaga, action.data);
    if (result) {
      yield put(actions.removePropertySagaPut({_id:pipeline, property}));
    }
  } catch (error) {
    console.error(error);
  }
}

function* pipelineUpdate(action) {
  try {
    const result = yield call(actions.fetchPipelineByIdSaga, action.data);
    if (result) {
      yield put(actions.updatePipelineSagaPut(result));
    }
  } catch (error) {
    console.error(error);
  }
}

function* watchPipelinePropertyAdd() {
  yield takeEvery(events.ADD_PIPELINE_PROPERTY_SAGA, addProperty);
}

function* watchPipelinePropertyRemove() {
  yield takeEvery(events.REMOVE_PIPELINE_PROPERTY_SAGA, removeProperty);
}

function* watchPipelineUpdate() {
  yield takeEvery(events.UPDATE_PIPELINE, pipelineUpdate);
}

const pipelineSagas = [
  fork(watchPipelinePropertyAdd),
  fork(watchPipelinePropertyRemove),
  fork(watchPipelineUpdate),
];

export default pipelineSagas;
