import { Toast, Text } from "native-base";

export const vh = (value) => {
  return `${(100 * value) / window.innerHeight}vh`;
};

export const vw = (value) => {
  return `${(100 * value) / window.innerWidth}vw`;
};

export const sleep = (milliseconds) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

export const percentDiff = (o, n) => {
  const d = n - o;
  return ((d / o) * 100).toFixed(2);
};

export const toFirstCase = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const formatPhoneNumber = (phoneNumberString) => {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return null;
};

export const parseAddress = (ff) => {
  if (!ff) {
    return {
      address1: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
      country: "",
    };
  }
  ff = ff.split(",");

  if (ff.length === 0) {
    return {
      address1: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
      country: "",
    };
  }

  let address1 = "";
  let address2 = "";
  let city = "";
  let state = "";
  let zip = "";
  let country = "";

  if (ff.length === 1) {
    ff = ff[0].split(" ");
    if (ff[ff.length - 1].length === 5) {
      zip = ff[ff.length - 1];
      state = ff[ff.length - 2];
      city = ff[ff.length - 3];
      address1 = ff.join().replace(zip, "");
      address1 = address1.replace(state, "");
      address1 = address1.replace(city, "");
      ff = address1 + ", " + city + ", " + state + " " + zip;
      ff = ff.split(",");
    }
  }

  let first = ff[0].trim();
  let last = ff[ff.length - 1].trim();

  let containsZip = /\d/.test(last);

  country = "";
  city = ff[ff.length - 3].trim();
  if (containsZip) {
    last = last.trim().split(" ");
    city = ff[ff.length - 2].trim();
  } else {
    country = last;
    last = ff[ff.length - 2].trim();
    last = last.split(" ");
  }

  address1 = first;
  address2 = city !== ff[1].trim() ? ff[1].trim() : "";
  if (address2 !== "") {
    city = ff[2].trim();
  }
  state = last[0].trim();
  zip = last[1].trim();
  return { address1, address2, city, state, zip, country };
};

export const showToast = (status, msg, duration) => {
  Toast.closeAll();
  return Toast.show({
    duration: duration || 3000,
    rrender: () => {
      let bg = "red.300";
      if (status === "error") {
        bg = "red.300";
      } else if (status === "success") {
        bg = "green.300";
      } else if (status === "info") {
        bg = "blueGray.300";
      } else if (status === "warning") {
        bg = "orange.300";
      }
      return (
        <Text
          style={{
            fontSize: vw(12),
            fontFamily: "Poppins",
            fontWeight: "500",
            color: "#33214B",
            boxShadow: "0px 3px 6px #0000001A",
          }}
          p={2}
          bg={bg}
          rounded={"full"}
        >
          {msg}
        </Text>
      );
    },
  });
};
//   function vhToPixel(value) {
//     return document.querySelector("#vhToPixel").innerHTML = `Result = ${(window.innerHeight * value) / 100}px`;
//   }
//   function vwToPixel(value) {
//     return document.querySelector("#vwToPixel").innerHTML = `Result = ${((window.innerWidth * value) / 100)}px`;
//   }
const Util = {
  vh,
  vw,
};

export default Util;
