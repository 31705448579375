import * as events from "../events";
import _ from "lodash";

const initialState = {
  listBuyer: [],
  buyers: [],
  listcontacts: {},
  total: {},
  emailStats: [],
  smsStats: [],
  buyerSmtp: [],
  purchasedNumbers: [],
  alertNumbers: [],
  emailActivity: {},
  smsActivity: [],
  fetchedContact: null,
  activeBuyer: null,
  refreshList: null,
  test: 0,
  scheduleTime: null,
  selectContact: false,
  landingpage: null,
};

const buyer = (state = initialState, action) => {
  switch (action.type) {
    case events.CREATE_BUYER_LIST:
      const activeBuyer = state.activeBuyer ? state.activeBuyer : action.data;
      return {
        ...state,
        listBuyer: [...state.listBuyer, action.data],
        activeBuyer,
      };
    case events.SELECT_CONTACT:
      return {
        ...state,
        selectContact: state.selectContact ? false : true,
      };
    case events.DELETE_BUYER_LIST:
      let dp = state.listBuyer;
      const idx = dp.findIndex((f) => f.list === action.data.list);
      dp = dp.filter((f) => f.list !== action.data.list);
      const nextList =
        state.activeBuyer && state.activeBuyer.list === action.data.list
          ? dp.length > 0 && dp[idx]
            ? dp[idx]
            : dp[idx - 1]
          : state.activeBuyer;
      return {
        ...state,
        test: state.test + 1,
        listBuyer: [...dp],
        activeBuyer: nextList,
      };
    case events.SET_ACTIVE_BUYER_LIST:
      return {
        ...state,
        activeBuyer: action.data,
      };
    case events.SET_BUYER_LIST:
      let ps = state.listBuyer;
      if (ps.length > 0) {
        if (ps.length > 1) {
          ps = ps.map((p) => {
            const ad = action.data.find((ap) => ap.list === p.list);
            if (ad) {
              p = ad;
            }
            return p;
          });
        } else {
          ps = [...action.data];
        }
      } else {
        ps = action.data;
      }
      ps = _.sortBy(ps, "ord");
      const defaultList = !state.activeBuyer
        ? ps.length > 0
          ? ps[0]
          : null
        : state.activeBuyer;
      return {
        ...state,
        listBuyer: ps,
        activeBuyer: defaultList,
        scheduleTime: action.schedule_time,
      };
      
    case events.CREATE_BUYER_CONTACT:
      return {
        ...state,
        buyers: [action.data, ...state.buyers],
        fetchedContact: action.data,
      };
    case events.UPDATE_BUYER_CONTACT:
      const ubc = action.data;
      const uc = state.buyers.map((c) => {
        if (c.contact === ubc.contact) {
          return { ...c, ...ubc };
        } else {
          return c;
        }
      });
      return {
        ...state,
        buyers: uc,
        fetchedContact: action.data,
      };
    case events.DELETE_BUYER_CONTACT:
      return {
        ...state,
        buyers: action.data,
      };
    case events.SET_BUYER_CONTACTS:
      return {
        ...state,
        buyers: action.data,
        refreshList: action.data,
        total: { ...state.total, [action.buyer]: action.total },
        emailStats: {
          ...state.emailStats,
          [action.buyer]: action.emailStats,
        },
        smsStats: {
          ...state.smsStats,
          [action.buyer]: action.smsStats,
        },
      };
    case events.FETCHED_CONTACT:
      return {
        ...state,
        fetchedContact: action.data,
      };
    case events.ADD_BUYER_CONTACT_NOTES:
      const buyerIndex = state.buyers.findIndex(
        (buyer) => buyer.contact === action.data.contact
      );
      if (buyerIndex !== -1) {
        const updatedBuyers = [...state.buyers];
        updatedBuyers[buyerIndex] = {
          ...updatedBuyers[buyerIndex],
          notes: action.data.notes,
        };
        return {
          ...state,
          buyers: updatedBuyers,
        };
      }
      return state;

    case events.SET_BUYER_EMAIL_SMTP:
      return {
        ...state,
        buyerSmtp: action.data,
      };
    case events.CREATE_BUYER_EMAIL_SMTP:
      return {
        ...state,
        buyerSmtp: [...state.buyerSmtp, action.data],
      };
    case events.UPDATE_EMAIL_SMTP_POSITION:
      const updateSmtpPosition = action.data;
      const smtpPosition = state.buyerSmtp.map((p) => {
        if (p.position === updateSmtpPosition.position) {
          return { ...p, ...updateSmtpPosition };
        } else {
          return p;
        }
      });
      return {
        ...state,
        buyerSmtp: smtpPosition,
      };
    case events.FETCH_PURCHASED_NUMBERS:
      return {
        ...state,
        purchasedNumbers: action.data,
      };
    case events.FETCH_ALERT_NUMBERS:
      return {
        ...state,
        alertNumbers: action.data,
      };
    case events.CREATE_NUMBER_FOR_ALERTS:
      return {
        ...state,
        alertNumbers: [...state.alertNumbers, action.data],
      };
    case events.DELETE_BUYER_NUMBER:
      const updatedNumbers = state.alertNumbers.filter(
        (n) => n._id !== action.data._id
      );
      return {
        ...state,
        alertNumbers: updatedNumbers,
      };
    case events.FETCH_EMAIL_ACTIVITY:
      return {
        ...state,
        emailActivity: action.data,
      };
    case events.FETCH_SMS_ACTIVITY:
      return {
        ...state,
        smsActivity: action.data,
      };

    case events.DELETE_BUYER_SMTP:
      const updatedsmtp = state.buyerSmtp.filter(
        (s) => s._id !== action.data._id
      );
      return {
        ...state,
        buyerSmtp: updatedsmtp,
      };
    case events.SET_BUYER_LANDING_PAGE:
      return {
        ...state,
        landingpage: action.data,
      };
    case events.REFRESH_CONTACTS:
      return {
        ...state,
        refreshList: action.data,
      };

    default:
      return state;
  }
};

export default buyer;
