import { SET_CREDITS } from "../events";

const initialState = {
  creditsHistory: [],
  total: 0,
};

const credits = (state = initialState, action) => {
  switch (action.type) {
    case SET_CREDITS:
      return {
        ...state,
        total: action.count,
        creditsHistory: action.data,
      };
    default:
      return state;
  }
};

export default credits;
