export const GOOGLE_API_KEY = "AIzaSyDmwZdL8fB-zBbuBfm6cXl_IgaMvkH-KLE";
export const UUID_NAMESPACE = "29cc8a0d-747c-5f85-9ff9-f2f16636d963";
// export const API_URL = "https://api.propflip.io/v2"; //live-propflip
export const API_URL = "https://demoapi.propflip.io/v2"; //test-propflip
// export const API_URL = "http://localhost:8080/v2"; //live-propflip
export const CDN = "https://cdn.propflip.io";
export const DRIVE_SERVER = "https://drive.propflip.io";
export const CHAT_SERVER = "https://chat.propflip.io";
export const GEO_API = "https://geo.propflip.io";
export const PROPERTY_API = "https://property.propflip.io";

export const MELISSA = `https://property.melissadata.net/v4/WEB/LookupProperty/?format=json&id=IC5wwKfX7K8_kLEgrKLrba**nSAcwXpxhQ0PC2lXxuDAZ-**`;

export const STRIPE_PK =
  "pk_live_51NLgheGF2K0riSA2EgT8YiQHSIi7EicxZfrS9QToOODbDDfntboZ21c4sbvPojL1NwkGPQXzilsqxvOlbWWybEPw00oMEw7aLU";
export const STRIPE_SK =
  "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";

// export const STRIPE_SK = "sk_test_51NLgheGF2K0riSA2VhLb2dUIH9Tqv13r3YlmyWklaREQPGKGRzdSP088o300v1mqspfXBFLv7q1zPODzpoy7NSjY00ksuOc4VY";
// export const STRIPE_PK = "pk_test_51NLgheGF2K0riSA2vE8bQfGSpWxkJmrXboExnsBTI8e0lg6vAt9sQ1ueRcAp5rWCmlka058Wml7WOKjSrfgZcuQj00VEZKt1RG";

export const getUser = () => {
  if (localStorage.getItem("@shadowuser") !== null) {
    const user = JSON.parse(localStorage.getItem("@shadowuser"));
    return user;
  }
  return null;
};

export const setUser = (key, value) => {
  if (localStorage.getItem("@shadowuser") !== null) {
    let user = JSON.parse(localStorage.getItem("@shadowuser"));
    user[key] = value;
    localStorage.setItem("@shadowuser", JSON.stringify(user));
    return JSON.parse(localStorage.getItem("@shadowuser"));
  }
  return null;
};

export const getProfile = () => {
  if (localStorage.getItem("@profile") !== null) {
    const profile = JSON.parse(localStorage.getItem("@profile"));
    return profile;
  }
  return null;
};
const config = {};

export default config;
