import * as events from "../events";

const initialState = {
  tasks: null,
  daytasks: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case events.SET_TASKS:
      return {
        ...state,
        tasks: action.data,
        //subscribed
      };
    case events.SET_DAY_TASKS:
      return {
        ...state,
        daytasks: action.data,
        //subscribed
      };
  }
  return state;
};
