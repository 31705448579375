import * as events from "../events";
import { API_URL, getUser } from "../../constants/Config";

export const fetchPipelines = (data) => {
  return async (dispatch, getState) => {
    const user = getUser();
    if (!user) {
      return 0;
    }
    const { token } = getUser();

    const page = data && data.page ? data.page : 1;
    const limit = data && data.limit ? data.limit : 50;
    const _id = data && data._id ? data._id : null;

    const body = {
      skip: page === 1 ? 0 : (page - 1) * limit,
      limit,
      filter: {},
    };

    if (_id) {
      body.filter["_id"] = _id;
    }

    try {
      const response = await fetch(`${API_URL}/pipelines/get`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        const errorRes = await response.json();
        let message = "Something went wrong!";
        if (errorRes && errorRes.error) {
          message = errorRes.error;
        }
        throw new Error(message);
      }

      const resData = await response.json();


      dispatch({
        type: events.SET_PIPELINES,
        data: resData.data ? resData.data : [],
      });
      return resData && resData.data && resData.data.length > 0
        ? resData.data[0].total
        : 0;
    } catch (err) {
      // send to custom analytics server
      console.log("Error ", err);
      throw err;
    }
  };
};

export const fetchPipelineProperties = (data) => {
  return async (dispatch, getState) => {
    const { token, role, _id: UserId } = getUser();
    // const pipelines = getState().pipelines;
    const { page, limit, _id, search } = data;
    const body = {
      skip: page === 1 ? 0 : (page - 1) * limit,
      limit,
      filter: {},
      selected: global.chosenProperties || [],
      unselected: global.unSelectedProperties || [],
      search,
    };
    if (role === "user") {
      body.filter["createdby"] = UserId;
    }

    try {
      const response = await fetch(`${API_URL}/pipelines/properties/${_id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        const errorRes = await response.json();
        let message = "Something went wrong!";
        if (errorRes && errorRes.error) {
          message = errorRes.error;
        }
        throw new Error(message);
      }

      const resData = await response.json();
      dispatch({
        type: events.SET_PIPELINE_PROPERTIES,
        data: resData.data ? resData.data : [],
        pipeline: _id,
        total: resData.count ? resData.count : 0,
      });

      if (global.chosenProperties && global.chosenProperties.length > 0) {
        delete global.selectedProperties;
        global.chosenProperties = [];
      }
      if (
        global.unSelectedProperties &&
        global.unSelectedProperties.length > 0
      ) {
        delete global.unSelectedProperties;
      }
    } catch (err) {
      // send to custom analytics server
      console.log("Error ", err);
      throw err;
    }
  };
};

export const addPipeline = (data, socket = null) => {
  return async (dispatch, getState) => {
    const { token } = getUser();
    // const pipelines = getState().pipelines.pipelines;
    const response = await fetch(`${API_URL}/pipelines/add`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      const errorRes = await response.json();
      let message = "Something went wrong!";
      if (errorRes && errorRes.error) {
        message = errorRes.error;
      }
      throw new Error(message);
    }

    const resData = await response.json();
    if (resData.data) {
      // let updatedPipelines = [];
      // if (pipelines && pipelines.length > 0) {
      //   updatedPipelines = [...pipelines, resData.data];
      // } else {
      //   updatedPipelines = [resData.data];
      // }
      if (socket) {
        socket.emit("pipeline:add", resData.data);
      }
      dispatch({
        type: events.CREATE_PIPELINE,
        data: resData.data,
      });
    }
  };
};

export const movePipeline = (data, socket = null) => {
  return async (dispatch, getState) => {
    try {
      const { token } = getUser();
      const response = await fetch(`${API_URL}/pipelines/move`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const errorRes = await response.json();
        let message = "Something went wrong!";
        if (errorRes && errorRes.error) {
          message = errorRes.error;
        }
        throw new Error(message);
      }
      const resData = await response.json();
      if (resData.data) {
        if (socket) {
          socket.emit("pipeline:move", data);
        }

        dispatch({
          type: events.MOVE_PIPELINES,
          data: data,
        });
      }
    } catch (err) {
      console.log("movepipeline", err);
      throw new Error("Cannot move pipeline");
    }
  };
};

export const addPropertySaga = async (data) => {
  try {
    const { token } = getUser();
    // const property = data.data;
    delete data.data;
    const response = await fetch(`${API_URL}/pipelines/add/properties`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    });
    const resData = await response.json();
    return resData.success;
  } catch (err) {
    console.log(err.message);
    return null;
  }
};

export const removePropertySaga = async (data) => {
  try {
    const { pipeline, property } = data;
    const { token } = getUser();

    const response = await fetch(
      `${API_URL}/pipelines/properties/${pipeline}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({ property }),
      }
    );

    const resData = await response.json();
    if (resData.success) {
      return true;
    }
    return false;
  } catch (err) {
    console.log(err.message);
    return false;
  }
};

export const fetchPipelineByIdSaga = async (data) => {
  try {
    const { token } = getUser();
    const page = data && data.page ? data.page : 1;
    const limit = data && data.limit ? data.limit : 50;
    const _id = data && data._id ? data._id : null;
    const body = {
      skip: page === 1 ? 0 : (page - 1) * limit,
      limit,
      filter: {},
    };

    if (_id) {
      body.filter["_id"] = _id;
    }

    const response = await fetch(`${API_URL}/pipelines/get`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      const errorRes = await response.json();
      let message = "Something went wrong!";
      if (errorRes && errorRes.error) {
        message = errorRes.error;
      }
      throw new Error(message);
    }

    const resData = await response.json();
    return resData.data ? resData.data : [];
  } catch (err) {
    console.log(err.message);
    return null;
  }
};

export const addPropertySagaPut = (data) => {
  return { type: events.ADD_PIPELINE_PROPERTY, data };
};

export const removePropertySagaPut = (data) => {
  return { type: "DELETE_PIPELINE_PROPERTY", data };
};

export const updatePipelineSagaPut = (data) => {
  return { type: "SET_PIPELINES", data };
};

export const saveProperty = (data, socket = null) => {
  return async (dispatch, getState) => {
    const { token } = getUser();
    data["skip"] = 0;
    const response = await fetch(`${API_URL}/pipelines/add/properties`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      const errorRes = await response.json();
      let message = "Something went wrong!";
      if (errorRes && errorRes.error) {
        message = errorRes.error;
      }
      throw new Error(message);
    }

    const resData = await response.json();

    if (resData && resData.data) {
      if (socket) {
        if (resData.data.length > 0) {
          socket.emit("pipeline:property:add", resData.data);
        }
      }
      dispatch({
        type: events.SET_PIPELINES,
        data: resData.data,
      });
    }
    return { type: "PROPERTY_SAVED" };
  };
};

export const moveProperty = (data, socket = null) => {
  return async (dispatch, getState) => {
    const { token } = getUser();
    const { property } = data;
    delete data.property;
    const response = await fetch(
      `${API_URL}/pipelines/properties/move/${property}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(data),
      }
    );

    if (!response.ok) {
      const errorRes = await response.json();
      let message = "Something went wrong!";
      if (errorRes && errorRes.error) {
        message = errorRes.error;
      }
      throw new Error(message);
    }

    const resData = await response.json();
    if (resData.data) {
      if (socket) {
        socket.emit("pipeline:property:move", { ...data, property });
      }
      dispatch({
        type: events.MOVE_PROPERTY,
        data: { ...data, property },
      });
    }
  };
};

export const moveProperties = async (data) => {
  const { token } = getUser();
  const response = await fetch(`${API_URL}/pipelines/properties/move`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const errorRes = await response.json();
    let message = "Something went wrong!";
    if (errorRes && errorRes.error) {
      message = errorRes.error;
    }
    throw new Error(message);
  }

  const resData = await response.json();
  return resData.success ? true : false;
};

export const removeProperties = async (data) => {
  const { token } = getUser();
  try {
    const { _id } = data;
    delete data._id;
    const response = await fetch(
      `${API_URL}/pipelines/properties/deletemany/${_id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(data),
      }
    );

    if (!response.ok) {
      const errorRes = await response.json();
      let message = "Something went wrong!";
      if (errorRes && errorRes.error) {
        message = errorRes.error;
      }
      throw new Error(message);
    }

    const resData = await response.json();
    return resData.success ? true : false;
  } catch (err) {
    throw new Error(err.message);
  }
};

export const deleteProperty = (data, socket = null) => {
  return async (dispatch, getState) => {
    const { token } = getUser();
    const { _id } = data;
    delete data._id;

    const response = await fetch(`${API_URL}/pipelines/properties/${_id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      const errorRes = await response.json();
      let message = "Something went wrong!";
      if (errorRes && errorRes.error) {
        message = errorRes.error;
      }
      throw new Error(message);
    }

    const resData = await response.json();

    if (resData && resData.data) {
      if (socket) {
        socket.emit("pipeline:property:del", resData.data);
      }
      dispatch({
        type: events.DELETE_PIPELINE_PROPERTY,
        data: resData.data,
      });
    }
  };
};

export const deletePipeline = (_id, socket = null) => {
  return async (dispatch, getState) => {
    const { token } = getUser();
    // const pipelines = getState().pipelines.pipelines;

    const response = await fetch(`${API_URL}/pipelines/${_id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({}),
    });

    if (!response.ok) {
      const errorRes = await response.json();
      let message = "Something went wrong!";
      if (errorRes && errorRes.error) {
        message = errorRes.error;
      }
      throw new Error(message);
    }

    const resData = await response.json();
    if (socket) {
      socket.emit("pipeline:del", resData.data);
    }
    if (resData.data) {
      dispatch({
        type: events.DELETE_PIPELINES,
        data: resData.data,
      });
    }
  };
};

export const fetchPipelinesActivity = () => {
  return async (dispatch, getState) => {
    const { token } = getUser();
    try {
      const response = await fetch(`${API_URL}/user/get-user-activity`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });

      if (!response.ok) {
        const errorRes = await response.json();
        let message = "Something went wrong!";
        if (errorRes && errorRes.error) {
          message = errorRes.error;
        }
        throw new Error(message);
      }

      // const resData = await response.json();

      // const loadedActivities = await resData.result.filter(
      //   (item) => item.crm === 1
      // );

      // dispatch({
      //   type: events.SET_ACTIVITIES,
      //   data: loadedActivities,
      // });
    } catch (err) {
      // send to custom analytics server
      console.log("Error ", err);
      throw err;
    }
  };
};
