import * as events from "../events";

const initialState = {
  templateMailers: [],
  templateStyles: [
    {
      id: 1,
      name: "Template 1",
    },
    {
      id: 2,
      name: "Template 2",
    },
    {
      id: 3,
      name: "Template 3",
    },
    {
      id: 4,
      name: "Template 4",
    },
  ],
  userSignatures: [],
  userTemplates: [],
  currentTemplate: null,
  total: 0,
};

const templates = (state = initialState, action) => {
  switch (action.type) {
    case events.SET_TEMPLATES:
      return {
        ...state,
        currentTemplate: null,
        userTemplates: [...action.data],
        total: action.total ? action.total : 0,
      };
    case events.SET_CURRENT_TEMPLATE:
      return {
        ...state,
        currentTemplate: action.data,
      };
    case events.SET_TEMPLATE_STYLES:
      return {
        ...state,
        templateStyles: action.templateStyles,
      };
    default:
      return state;
  }
};

export default templates;
