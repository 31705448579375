import * as events from "../events";

const initialState = {
  plan: null,
  subscribed: null,
  addons: null,
};

const plan = (state = initialState, action) => {
  switch (action.type) {
    case events.SET_PLANS:
      //const subscribed = action.data.filter(item => item.active_plan !== null);
      return {
        ...state,
        plan: action.data,
        //subscribed
      };
    case events.SET_ADDONS:
      return {
        ...state,
        addons: action.data,
      };

    case events.SUBSCRIBE:
      return {
        ...state,
        subscribed: action.data,
      };

    case events.CANCEL:
      return {
        ...state,
        subscribed: null,
      };
    default:
      return state;
  }
};

export default plan;
