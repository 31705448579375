import * as events from "../events";
import moment from "moment";

const initialState = {
  availableProperties: [],
  filteredProperties: [],
  userAddedProperties: [],
  searchedProperties: [],
  bulkProperties: [],
  lastAddedProperty: null,
  lastFetchedAddress: null,
  lastFetchedOwner: null,
  fetchedProperty: null,
  flu: null,
  progress: 0,
  statuslist: null,
  tags: null,
  notes: null,
  smartsearch: null,
  credits: 0,
  page: 1,
  total: 0,
  clicked: 0,
  timer: null,
  refreshOnback: null,
  propertyFilter: {
    fromDate: moment().subtract(1, "year").format("YYYY-MM-DD"),
    toDate: moment().format("YYYY-MM-DD"),
    filteredTags: [],
    filteredStatus: [],
    filteredBedrooms: [],
    filteredBathrooms: [],
    filteredFloors: [],
    filteredBuiltyear: [],
    filteredSqft: [],
    filteredAcre: [],
    filteredEv: [],
    sortBy: "tid",
    orderBy: "desc",
    isUserFilter: false,
  },
  archivesTotal: 0,
  archives: {},
};

const getUnique = (arr, comp) => {
  const unique = arr
    .map((e) => e[comp])

    // store the keys of the unique objects
    .map((e, i, final) => final.indexOf(e) === i && i)

    // eliminate the dead keys & store unique objects
    .filter((e) => arr[e])
    .map((e) => arr[e]);

  return unique;
};

const properties = (state = initialState, action) => {
  switch (action.type) {
    case events.SET_CLICKED:
      return {
        ...state,
        clicked: state.clicked + 1,
      };
    // case events.FILTER_LAST_UPDATED:
    //   return {
    //     ...state,
    //     flu: action.data,
    //   };
    case events.CLEAR_LAST_FETCHEDADDRESS:
      return {
        ...state,
        lastFetchedAddress: null,
      };
    case events.SET_TIMER:
      return {
        ...state,
        timer: action.data,
      };
    case events.CREDIT_BALANCE:
      return {
        ...state,
        credits: action.data,
      };
    case events.SET_PROGRESS:
      return {
        ...state,
        progress: action.progress,
      };
    case events.SET_BACK:
      return {
        ...state,
        refreshOnback: action.back,
      };
    case events.SMART_SEARCH:
      return {
        ...state,
        smartsearch: action.data,
      };
    case events.FETCHED_PROPERTY:
      return {
        ...state,
        fetchedProperty: action.data,
      };
    case events.SET_STATUS_LIST:
      return {
        ...state,
        statuslist: action.data,
      };
    case events.SET_TAGS:
      return {
        ...state,
        tags: action.data,
      };
    case events.SET_NOTES_LIST:
      return {
        ...state,
        notes: action.data,
      };
    case events.SET_PROPERTIES:
      return {
        ...state,
        total: action.total ? action.total : 0,
        page: action.page ? action.page : state.page,
        availableProperties: [...action.properties], //state.availableProperties.concat(action.properties)
      };
    case events.SET_SEARCHED_PROPERTIES:
      // const start = moment();
      // const uniqueProperties = getUnique(
      //   [...state.availableProperties, ...action.properties],
      //   "_id"
      // );

      return {
        ...state,
        total: action.total ? action.total : state.total,
        page: action.page ? action.page : state.page,
        searchedProperties: action.properties ? action.properties : [], //state.availableProperties.concat(action.properties)
      };
    case events.SET_PROPERTY_FILTER:
      if (!action.data.isUserFilter) {
        return {
          ...state,
          total: 0,
          filteredProperties: [],
          propertyFilter: {
            fromDate: action.data.fromDate,
            toDate: moment().format("YYYY-MM-DD"),
            filteredTags: [],
            filteredStatus: [],
            filteredBedrooms: [],
            filteredBathrooms: [],
            filteredFloors: [],
            filteredBuiltyear: [],
            filteredSqft: [],
            filteredAcre: [],
            filteredEv: [],
            sortBy: "tid",
            orderBy: "desc",
            isUserFilter: false,
            lastUpdated: moment().valueOf(),
          },
        };
      } else {
        if (action.refresh && state.propertyFilter.isUserFilter) {
          return { ...state };
        }
        return {
          ...state,
          propertyFilter: { ...action.data },
        };
      }
    case events.SET_FILTERED_PROPERTIES:
      let uniqueFilteredProperties = [];
      if (action.page) {
        uniqueFilteredProperties = action.properties;
      } else {
        uniqueFilteredProperties = getUnique(
          [...state.filteredProperties, ...action.properties],
          "_id"
        );
      }
      return {
        ...state,
        total: action.total,
        // page:action.page,
        filteredProperties: [...uniqueFilteredProperties],
      };
    case events.SET_NEW_PROPERTY:
      return {
        ...state,
        lastAddedProperty: null,
        lastFetchedAddress: null,
        lastFetchedOwner: null,
      };

    case events.ADD_BULK_PROPERTY:
      return {
        ...state,
        bulkProperties: [...state.bulkProperties, action.data],
      };
    case events.CREATE_PROPERTY:
      // const mp = {
      //   _id: action.data._id,
      //   tid: action.data.tid,
      //   name: action.data.name,
      //   coords: action.data.coords,
      // };
      global.propertiesAdded = global.propertiesAdded
        ? global.propertiesAdded + 1
        : 1;
      const ap = state.availableProperties;
      const idx = ap.findIndex((p) => p.tid === action.data.tid);
      if (idx >= 0) {
        return {
          ...state,
        };
      }
      return {
        ...state,
        total: parseInt(state.total) + 1,
        // mapProperties: [mp, ...state.mapProperties],
        lastAddedProperty: action.data,
        fetchedProperty: action.data,
        availableProperties: [action.data, ...state.availableProperties],
      };

    case events.ADD_BULK_PROPERTIES:
      // const mps = [];
      // if (action.data.length > 0) {
      //   action.data.map((a) => {
      //     mps.push({
      //       _id: a._id,
      //       tid: a.tid,
      //       name: a.name,
      //       coords: a.coords,
      //     });
      //     return a;
      //   });
      // }

      const added = action.data ? action.data.length : 0;
      return {
        ...state,
        total: parseInt(state.total) + added,
        // mapProperties: [...mps, ...state.mapProperties],
        availableProperties: [...action.data, ...state.availableProperties],
      };

    case events.GET_PROPERTY_ADDRESS:
      return {
        ...state,
        lastFetchedAddress: action.lastFetchedAddress,
      };

    case events.GET_PROPERTY_OWNER:
      return {
        ...state,
        lastFetchedOwner: action.lastFetchedOwner,
      };
    case events.UPLOAD_PROPERTY_PICTURE:
      return { ...state, lastUploadedPicture: action.lastUploadedPicture };
    case events.PROPERTY_RT_UPDATE:
      const rtp = state.availableProperties;
      const rp = rtp.map((item) => {
        if (item._id === action.data._id) {
          for (const i in item) {
            if (Object.keys(action.data).includes(i)) {
              item[i] = action.data[i];
            }
          }
        }
        return item;
      });

      return {
        ...state,
        fetchedProperty: action.data,
        availableProperties: [...rp],
      };
    case events.SET_ARCHIVES:
      return {
        ...state,
        archivesTotal: action.total ? action.total : state.total,
        archives: action.data,
      };
    default:
      return state;
  }
};

export default properties;
