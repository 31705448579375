import * as events from "../events";

const initialState = {
  userCampaigns: [],
  total: 0,
  currentCampaign: null,
  contacts: [],
  recentlogs: [],
  searchContacts: [],
  purchasedContacts: [],
  getSms: null,
  chats: [],
  total: {},
};

const campaigns = (state = initialState, action) => {
  switch (action.type) {
    case events.SET_CAMPAIGNS:
      return {
        ...state,
        userCampaigns: action.campaigns,
        total: action.total,
      };

    case events.SET_CURRENT_CAMPAIGN:
      return {
        ...state,
        currentCampaign: action.data,
      };

    case events.CREATE_CAMPAIGN:
      let userCampaigns = state.userCampaigns;
      if (action.data.default) {
        userCampaigns = state.userCampaigns.map((c) => {
          c.default = false;
          return c;
        });
      }
      return {
        ...state,
        currentCampaign: null,
        userCampaigns: [action.data, ...userCampaigns],
      };

    case events.UPDATE_CAMPAIGN:
      const campaignIndex = state.userCampaigns.findIndex(
        (sign) => sign._id === action.data._id
      );

      let updatedUserCampaigns = [...state.userCampaigns];
      if (action.data.default) {
        updatedUserCampaigns = updatedUserCampaigns.map((c) => {
          c.default = false;
          return c;
        });
      }

      updatedUserCampaigns[campaignIndex] = action.data;

      return {
        ...state,
        currentCampaign: null,
        userCampaigns: updatedUserCampaigns,
      };

    case events.DELETE_CAMPAIGN:
      return {
        ...state,
        currentCampaign: null,
        userCampaigns: state.userCampaigns.filter(
          (campaign) => campaign._id !== action._id
        ),
      };
    case events.SET_CONTACTS:
      return {
        ...state,
        contacts: action.data,
      };
    case events.SET_RECENT_LOGS:
      return {
        ...state,
        recentlogs: action.data,
      };
    case events.SET_SEARCH:
      return {
        ...state,
        searchContacts: action.data,
        total: { ...state.total, ["searchNumbers"]: action.total },
        // total: action.total,
      };
    case events.SET_PURCHASED_CONTACTS:
      return {
        ...state,
        purchasedContacts: action.data,
        // total: { ...state.total, [action.buyer]: action.total },
      };
    case events.SET_SMS_DATA:
      return {
        ...state,
        getSms: action.data,
        // chats: action.chats,
      };
    case events.SET_CHAT_DATA:
      return {
        ...state,
        chats: action.chats,
      };
    default:
      return state;
  }
};

export default campaigns;
