import moment from "moment-timezone";

import * as events from "../events";
import { API_URL, getUser } from "../../constants/Config";

export const fetchLists = (data) => {
  return async (dispatch, getState) => {
    const user = getUser();
    if (!user) {
      return 0;
    }
    const { token } = getUser();

    const page = data && data.page ? data.page : 1;
    const limit = data && data.limit ? data.limit : 50;
    const _id = data && data._id ? data._id : null;

    const body = {
      skip: page === 1 ? 0 : (page - 1) * limit,
      limit,
      filter: {},
    };

    if (_id) {
      body.filter["_id"] = _id;
    }

    if (data && data.user) {
      if (data.user._id !== user._id) {
        body["user"] = data.user._id;
      }
    }

    try {
      const response = await fetch(`${API_URL}/lists/get`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        const errorRes = await response.json();
        let message = "Something went wrong!";
        if (errorRes && errorRes.error) {
          message = errorRes.error;
        }
        throw new Error(message);
      }

      const resData = await response.json();

      if (data && data.user) {
        if (resData && resData.data && resData.data.length) {
          dispatch({
            type: events.SET_LIST_USER,
            data: data.user,
          });
          dispatch({
            type: events.SET_USER_LISTS,
            data: resData.data ? resData.data : [],
          });
        } else {
          throw new Error("No lists found for user " + data.user.name);
        }
      } else {
        dispatch({
          type: events.SET_LIST_USER,
          data: {
            _id: user._id,
            name: user.name,
          },
        });
        await dispatch({
          type: events.SET_USER_LISTS,
          data: [],
        });
        await dispatch({
          type: events.SET_LISTS,
          data: resData.data ? resData.data : [],
        });
      }

      delete global.su;

      return resData && resData.data && resData.data.length > 0
        ? resData.data[0].total
        : 0;
    } catch (err) {
      // send to custom analytics server
      console.log("Error ", err);
      throw err;
    }
  };
};

export const fetchListProperties = (data) => {
  return async (dispatch, getState) => {
    const { token, joined } = getUser();
    const { page, limit, _id, name, search } = data;
    const isFiltered = filtersTotal(_id) > 0;
    let storedFilter = localStorage.getItem("@listFilter:" + _id);

    const filter = {};
    let sort = { _id: -1 };
    if (isFiltered) {
      storedFilter = JSON.parse(storedFilter);
      if (storedFilter.date && storedFilter.date.from && storedFilter.date.to) {
        filter["fromdate"] = moment(storedFilter.date.from)
          .startOf("day")
          .toISOString();
        filter["todate"] = moment(storedFilter.date.to)
          .endOf("day")
          .toISOString();
      }

      if (storedFilter.filterby.length > 0) {
        const storedFilterBy = storedFilter.filterby.reduce((acc, item) => {
          acc[item] = true;
          return acc;
        }, {});
        filter["filterby"] = storedFilterBy;
      }

      if (storedFilter.campaign.length > 0) {
        filter["campaign"] = storedFilter.campaign;
      }

      if (storedFilter.tags.length > 0) {
        filter["tags"] = storedFilter.tags;
      }

      if (storedFilter.status.length > 0) {
        filter["status"] = storedFilter.status;
      }

      if (storedFilter.bath.length > 0) {
        filter["bath"] = storedFilter.bath.map((b) => {
          if (b === "10+") {
            b = 10;
          }
          if (b === "NA") {
            b = -1;
          }
          // return parseInt(b);
          return b === "all" ? b : parseFloat(b);
        });
      }

      if (storedFilter.bed.length > 0) {
        filter["bed"] = storedFilter.bed.map((b) => {
          if (b === "10+") {
            b = 10;
          }
          if (b === "NA") {
            b = -1;
          }
          // return parseInt(b);
          return b === "all" ? b : parseFloat(b);
        });
      }

      if (storedFilter.acre.length > 0) {
        filter["acre"] = storedFilter.acre
          .filter((f) => f != "<0.4999")
          .map((a) => {
            if (a === "0.0001-0.4999") {
              a = 0;
            } else if (a === "NA") {
              a = -1;
            }

            // return parseFloat(a);
            return a === "all" ? a : parseFloat(a);
          });
      }

      if (storedFilter.sqft.length > 0) {
        filter["sqft"] = storedFilter.sqft.map((a) => {
          // if (a === "20000+") {
          // a = 20000;
          // }
          if (a === "NA") {
            a = -1;
          }
          // return parseFloat(a);
          return a === "all" ? a : parseFloat(a);
        });
      }

      if (storedFilter.year.length > 0) {
        filter["yearbuilt"] = storedFilter.year.map((f) => f.toString());
      }

      if (storedFilter.ev.length > 0) {
        filter["ev"] = storedFilter.ev.map((a) => {
          a = a.replace(",", "");
          a = a.replace(",", "");
          a = a.replace(",", "");
          a = a.replace("$", "");
          a = a.replace("+", "");
          a = a.replace("0-99999", "99999");
          if (a === "NA") {
            a = -1;
          }
          // return parseInt(a);
          return a === "all" ? a : parseFloat(a);
        });
      }

      // let sortBy = storedFilter.sort;
      if (storedFilter.sort !== "") {
        let sortBy = storedFilter.sort;

        if (storedFilter.sort === "user") {
          sortBy = "createdUser.firstname";
        }

        sort = {
          [sortBy]: storedFilter.order === "desc" ? -1 : 1,
        };
      }
    } else {
      filter["fromdate"] = moment(joined).utc().startOf("day").toISOString();
      filter["todate"] = moment().utc().endOf("day").toISOString();
    }

    console.log("filter", filter);

    const body = JSON.stringify({
      skip: page === 1 ? 0 : (page - 1) * limit,
      limit: parseInt(limit),
      sort,
      search,
      fields: {
        _id: 1,
        name: 1,
        propertyaddress: 1,
        owneraddress: 1,
        coords: 1,
        bed: 1,
        bath: 1,
        floor: 1,
        sqft: 1,
        acre: 1,
        ev: 1,
        yearbuilt: 1,
        status: 1,
        tags: 1,
        apn: 1,
        tid: 1,
        mkt: 1,
        imported: 1,
        pic: 1,
        campaign: 1,
        "list._id": 1,
        "list.name": 1,
        created: 1,
        modified: 1,
        "createdUser._id": 1,
        "createdUser.firstname": 1,
        "createdUser.lastname": 1,
        "createdUser.pic": 1,
        "assignedUser._id": 1,
        "assignedUser.firstname": 1,
        "assignedUser.lastname": 1,
        "assignedUser.pic": 1,
        campaignstatus: 1,
        hotlead: 1,
      },
      filter,
      selected:
        global.chosenProperties && global.propertiesList === _id
          ? global.chosenProperties
          : [],
      unselected:
        global.unSelectedProperties && global.propertiesList === _id
          ? global.unSelectedProperties
          : [],
      all: data.all ? true : false,
      user: global.listUser ? global.listUser : null,
    });

    try {
      const response = await fetch(
        name === "All"
          ? `${API_URL}/properties/get`
          : `${API_URL}/lists/properties/${_id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body,
        }
      );

      if (!response.ok) {
        const errorRes = await response.json();
        let message = "Something went wrong!";
        if (errorRes && errorRes.error) {
          message = errorRes.error;
        }
        throw new Error(message);
      }

      const resData = await response.json();

      dispatch({
        type: events.SET_LIST_PROPERTIES,
        data: resData.data,
        list: _id,
        total: resData.count,
        statusCount: resData.status || [],
      });

      if (global.chosenProperties && global.chosenProperties.length > 0) {
        delete global.selectedProperties;
        global.chosenProperties = [];
      }
      if (
        global.unSelectedProperties &&
        global.unSelectedProperties.length > 0
      ) {
        delete global.unSelectedProperties;
      }
      delete global.propertiesList;
    } catch (err) {
      console.log("Error ", err);
      throw err;
    }
  };
};

export const addList = (data, socket = null) => {
  return async (dispatch, getState) => {
    const { token } = getUser();
    const { _id } = data;
    let url = `${API_URL}/lists/add`;
    let method = "POST";
    let edit = false;
    if (_id) {
      url = `${API_URL}/lists/${_id}`;
      delete data._id;
      method = "PATCH";
      edit = true;
    } else {
      delete data._id;
    }
    // const pipelines = getState().pipelines.pipelines;
    const response = await fetch(url, {
      method,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      const errorRes = await response.json();
      let message = "Something went wrong!";
      if (errorRes && errorRes.error) {
        message = errorRes.error;
      }
      throw new Error(message);
    }

    const resData = await response.json();
    if (resData.data) {
      // let updatedPipelines = [];
      // if (pipelines && pipelines.length > 0) {
      //   updatedPipelines = [...pipelines, resData.data];
      // } else {
      //   updatedPipelines = [resData.data];
      // }
      if (socket) {
        socket.emit("list:add", resData.data);
      }
      if (!edit) {
        dispatch({
          type: events.CREATE_LIST,
          data: resData.data,
        });
      } else {
        dispatch({
          type: events.SET_LISTS,
          data: [resData.data],
        });
      }
    }
  };
};

export const moveList = (data, socket = null) => {
  return async (dispatch, getState) => {
    try {
      const { token } = getUser();
      const response = await fetch(`${API_URL}/lists/move`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const errorRes = await response.json();
        let message = "Something went wrong!";
        if (errorRes && errorRes.error) {
          message = errorRes.error;
        }
        throw new Error(message);
      }
      const resData = await response.json();
      if (resData.data) {
        if (socket) {
          socket.emit("list:move", data);
        }
        dispatch({
          type: events.MOVE_LISTS,
          data: data,
        });
      }
    } catch (err) {
      console.log("movelist error", err);
      throw new Error("Cannot move list");
    }
  };
};

export const stopList = (_id, socket = null) => {
  return async (dispatch, getState) => {
    const { token } = getUser();
    const response = await fetch(`${API_URL}/lists/campaigns/stop/${_id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        campaignstatus: "stopped",
      }),
    });

    if (!response.ok) {
      const errorRes = await response.json();
      let message = "Something went wrong!";
      if (errorRes && errorRes.error) {
        message = errorRes.error;
      }
      throw new Error(message);
    }

    const resData = await response.json();
    if (socket) {
      socket.emit("list:stop", resData.data);
    }
    if (resData.data) {
      dispatch({
        type: events.SET_LISTS,
        data: [resData.data],
      });
    }
  };
};

export const addPropertySaga = async (data) => {
  try {
    const { token } = getUser();
    // const property = data.data;
    delete data.data;
    const response = await fetch(`${API_URL}/lists/add/properties`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    });
    const resData = await response.json();
    return resData;
  } catch (err) {
    console.log(err.message);
    return null;
  }
};

export const removePropertySaga = async (data) => {
  try {
    const { list, property } = data;
    const { token } = getUser();

    const response = await fetch(`${API_URL}/lists/property/${list}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ property }),
    });

    const resData = await response.json();
    if (resData.success) {
      return true;
    }
    return false;
  } catch (err) {
    console.log(err.message);
    return false;
  }
};

export const fetchListByIdSaga = async (data) => {
  try {
    const { token } = getUser();
    const page = data && data.page ? data.page : 1;
    const limit = data && data.limit ? data.limit : 50;
    const _id = data && data._id ? data._id : null;
    const body = {
      skip: page === 1 ? 0 : (page - 1) * limit,
      limit,
      filter: {},
    };

    if (_id) {
      body.filter["_id"] = _id;
    }

    const response = await fetch(`${API_URL}/lists/get`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      const errorRes = await response.json();
      let message = "Something went wrong!";
      if (errorRes && errorRes.error) {
        message = errorRes.error;
      }
      throw new Error(message);
    }

    const resData = await response.json();
    return resData.data ? resData.data : [];
  } catch (err) {
    console.log(err.message);
    return null;
  }
};

export const addPropertySagaPut = (data) => {
  return { type: events.ADD_LIST_PROPERTY, data };
};

export const removePropertySagaPut = (data) => {
  return { type: "DELETE_LIST_PROPERTY", data };
};

export const updateListSagaPut = (data) => {
  return { type: "SET_LISTS", data };
};

export const saveProperty = (data, socket = null) => {
  return async (dispatch, getState) => {
    const { token } = getUser();
    data["skip"] = 0;
    const response = await fetch(`${API_URL}/lists/add/properties`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      const errorRes = await response.json();
      let message = "Something went wrong!";
      if (errorRes && errorRes.error) {
        message = errorRes.error;
      }
      throw new Error(message);
    }

    const resData = await response.json();

    if (resData && resData.data) {
      if (socket) {
        if (resData.data.length > 0) {
          socket.emit("list:property:add", resData.data);
        }
      }
      dispatch({
        type: events.SET_LISTS,
        data: resData.data,
      });
    }
    return { type: "LIST_PROPERTY_SAVED" };
  };
};

export const moveProperty = (data, socket = null) => {
  return async (dispatch, getState) => {
    const { token } = getUser();
    const { property } = data;
    delete data.property;
    const response = await fetch(
      `${API_URL}/list/properties/move/${property}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(data),
      }
    );

    if (!response.ok) {
      const errorRes = await response.json();
      let message = "Something went wrong!";
      if (errorRes && errorRes.error) {
        message = errorRes.error;
      }
      throw new Error(message);
    }

    const resData = await response.json();
    if (resData.data) {
      if (socket) {
        socket.emit("list:property:move", { ...data, property });
      }
      dispatch({
        type: events.MOVE_LIST_PROPERTY,
        data: { ...data, property },
      });
    }
  };
};

export const deleteProperty = (data, socket = null) => {
  return async (dispatch, getState) => {
    const { token } = getUser();
    const { _id } = data;
    delete data._id;

    const response = await fetch(`${API_URL}/lists/properties/${_id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      const errorRes = await response.json();
      let message = "Something went wrong!";
      if (errorRes && errorRes.error) {
        message = errorRes.error;
      }
      throw new Error(message);
    }

    const resData = await response.json();

    if (resData && resData.data) {
      if (socket) {
        socket.emit("list:property:del", resData.data);
      }
      dispatch({
        type: events.DELETE_LIST_PROPERTY,
        data: resData.data,
      });
    }
  };
};

export const deleteList = (_id, socket = null) => {
  return async (dispatch, getState) => {
    const { token } = getUser();
    // const pipelines = getState().pipelines.pipelines;

    const response = await fetch(`${API_URL}/lists/${_id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({}),
    });

    if (!response.ok) {
      const errorRes = await response.json();
      let message = "Something went wrong!";
      if (errorRes && errorRes.error) {
        message = errorRes.error;
      }
      throw new Error(message);
    }

    const resData = await response.json();
    if (socket) {
      socket.emit("list:del", resData.data);
    }
    if (resData.data) {
      dispatch({
        type: events.DELETE_LISTS,
        data: resData.data,
      });
    }
  };
};

export const filtersTotal = (list) => {
  const storedFilters = localStorage.getItem("@listFilter:" + list);

  let total = 0;
  if (storedFilters) {
    const filters = JSON.parse(storedFilters);
    for (const filter in filters) {
      if (filter === "date") {
        const date = filters["date"];
        if (date.from && date.to) {
          total = total + 1;
        }
      } else if (filter === "sort") {
        if (filters["sort"] !== "") {
          total = total + 1;
        }
      } else if (filter === "order") {
        if (filters["order"] === "desc") {
          total = total + 1;
        }
      } else if (filter === "ts") {
        continue;
      } else {
        total = total + filters[filter].length;
      }
    }
  }
  return total;
};

export const getArchives = (data) => {
  return async (dispatch, getState) => {
    // const { token, profile } = getState().auth;
    const { token, joined } = getUser();
    const { page, limit, _id, name, search } = data;
    const isFiltered = filtersTotal(_id) > 0;
    let storedFilter = localStorage.getItem("@listFilter:" + _id);

    const filter = {};
    // const fields = {};
    let sort = { _id: -1 };
    if (isFiltered) {
      storedFilter = JSON.parse(storedFilter);
      if (storedFilter.date && storedFilter.date.from && storedFilter.date.to) {
        filter["fromdate"] = moment(storedFilter.date.from)
          .startOf("day")
          .toISOString();
        filter["todate"] = moment(storedFilter.date.to)
          .endOf("day")
          .toISOString();
      }

      if (storedFilter.tags.length > 0) {
        filter["tags"] = storedFilter.tags;
      }

      if (storedFilter.status.length > 0) {
        filter["status"] = storedFilter.status;
      }

      if (storedFilter.bath.length > 0) {
        filter["bath"] = storedFilter.bath.map((b) => {
          if (b === "10+") {
            b = 10;
          }
          if (b === "NA") {
            b = -1;
          }
          return parseInt(b);
        });
      }

      if (storedFilter.bed.length > 0) {
        filter["bed"] = storedFilter.bed.map((b) => {
          if (b === "10+") {
            b = 10;
          }
          if (b === "NA") {
            b = -1;
          }
          return parseInt(b);
        });
      }

      if (storedFilter.acre.length > 0) {
        filter["acre"] = storedFilter.acre
          .filter((f) => f != "<0.4999")
          .map((a) => {
            if (a === "0.0001-0.4999") {
              a = 0;
            } else if (a === "NA") {
              a = -1;
            }

            return parseFloat(a);
          });
      }

      if (storedFilter.sqft.length > 0) {
        filter["sqft"] = storedFilter.sqft.map((a) => {
          // if (a === "20000+") {
          // a = 20000;
          // }
          if (a === "NA") {
            a = -1;
          }
          return parseFloat(a);
        });
      }

      if (storedFilter.year.length > 0) {
        filter["yearbuilt"] = storedFilter.year.map((f) => f.toString());
      }

      if (storedFilter.ev.length > 0) {
        filter["ev"] = storedFilter.ev.map((a) => {
          a = a.replace(",", "");
          a = a.replace(",", "");
          a = a.replace(",", "");
          a = a.replace("$", "");
          a = a.replace("+", "");
          a = a.replace("0-99999", "99999");
          return parseInt(a);
        });
      }

      // let sortBy = storedFilter.sort;
      if (storedFilter.sort !== "") {
        let sortBy = storedFilter.sort;

        if (storedFilter.sort === "user") {
          sortBy = "createdUser.firstname";
        }

        sort = {
          [sortBy]: storedFilter.order === "desc" ? -1 : 1,
        };
      }
    } else {
      filter["fromdate"] = moment(joined).utc().startOf("day").toISOString();
      filter["todate"] = moment().utc().endOf("day").toISOString();
    }

    const body = JSON.stringify({
      skip: page === 1 ? 0 : (page - 1) * limit,
      limit: parseInt(limit),
      sort,
      search,
      fields: {
        _id: 1,
        name: 1,
        propertyaddress: 1,
        owneraddress: 1,
        coords: 1,
        bed: 1,
        bath: 1,
        floor: 1,
        sqft: 1,
        acre: 1,
        ev: 1,
        yearbuilt: 1,
        status: 1,
        tags: 1,
        apn: 1,
        tid: 1,
        mkt: 1,
        imported: 1,
        pic: 1,
        campaign: 1,
        "list._id": 1,
        "list.name": 1,
        created: 1,
        modified: 1,
        "createdUser._id": 1,
        "createdUser.firstname": 1,
        "createdUser.lastname": 1,
        "createdUser.pic": 1,
        "assignedUser._id": 1,
        "assignedUser.firstname": 1,
        "assignedUser.lastname": 1,
        "assignedUser.pic": 1,
        campaignstatus: 1,
        hotlead: 1,
      },
      filter,
      selected:
        global.chosenProperties && global.propertiesList === _id
          ? global.chosenProperties
          : [],
      unselected:
        global.unSelectedProperties && global.propertiesList === _id
          ? global.unSelectedProperties
          : [],
      all: data.all ? true : false,
      user: global.listUser ? global.listUser : null,
    });

    const response = await fetch(`${API_URL}/properties/archives`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body,
    });

    if (!response.ok) {
      const errorRes = await response.json();
      let message = "Something went wrong!";
      if (errorRes && errorRes.error) {
        message = errorRes.error;
      }
      throw new Error(message);
    }

    const resData = await response.json();

    if (resData.data) {
      dispatch({
        type: events.SET_ARCHIVES,
        data: resData.data,
        total: resData.count,
      });
    }
  };
};

export const fetchListCampaigns = (id) => {
  return async (dispatch, getState) => {
    const { token } = getUser();
    try {
      const response = await fetch(
        `${API_URL}/lists/properties/getcampaings/${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );

      if (!response.ok) {
        const errorRes = await response.json();
        let message = "Something went wrong!";
        if (errorRes && errorRes.error) {
          message = errorRes.error;
        }
        throw new Error(message);
      }

      const resData = await response.json();

      dispatch({
        type: events.SET_LIST_CAMPAIGNS,
        campaigns: resData.data ? resData.data : [],
      });
    } catch (err) {
      console.log("Error ", err);
      throw err;
    }
  };
};
