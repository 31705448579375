import * as events from "../events";
import _ from "lodash";
// import activities from "./activities";
// import properties from "./properties";

const initialState = {
  pipelines: [],
  activities: [{ _id: 1, properties: [] }],
  activePipeline: null,
  selectPipeline: false,
  properties: {},
  total: {},
  test: 0,
};

const getUnique = (arr, comp) => {
  const unique = arr
    .map((e) => e[comp])

    // store the keys of the unique objects
    .map((e, i, final) => final.indexOf(e) === i && i)

    // eliminate the dead keys & store unique objects
    .filter((e) => arr[e])
    .map((e) => arr[e]);

  return unique;
};

const pipelines = (state = initialState, action) => {
  switch (action.type) {
    case events.CREATE_PIPELINE:
      // const activePipeline = state.activePipeline
      //   ? state.activePipeline
      //   : action.data;
      const activePipeline = action.data;
      return {
        ...state,
        pipelines: [...state.pipelines, action.data],
        activePipeline,
      };
    case events.SELECT_PIPELINE:
      return {
        ...state,
        selectPipeline: state.selectPipeline ? false : true,
      };
    case events.SET_ACTIVE_PIPELINE:
      return {
        ...state,
        activePipeline: action.data,
      };
    case events.SET_PIPELINES:
      let ps = state.pipelines;
      if (ps.length > 0) {
        ps = ps.map((p) => {
          const ad = action.data.find((ap) => ap._id === p._id);
          if (ad) {
            p = ad;
          }
          return p;
        });
      } else {
        ps = action.data;
      }
      ps = _.sortBy(ps, "ord");
      const defaultPipeline = !state.activePipeline
        ? ps.length > 0
          ? ps[0]
          : null
        : state.activePipeline;

      return {
        ...state,
        pipelines: ps,
        activePipeline: defaultPipeline,
      };
    case events.MOVE_PIPELINES:
      let xp = state.pipelines;
      xp = _.orderBy(xp, "ord", "asc");
      let ft = action.data;

      const x = xp.findIndex((p) => p.ord === ft.from);
      const y = xp.findIndex((p) => p.ord === ft.to);
      const xord = xp[x].ord;
      const yord = xp[y].ord;
      xp[x].ord = yord;
      xp[y].ord = xord;
      xp = _.orderBy(xp, "ord", "asc");
      return {
        ...state,
        test: state.test + 1,
        pipelines: xp,
      };
    case events.DELETE_PIPELINES:
      let dp = state.pipelines;
      const idx = dp.findIndex((f) => f._id === action.data._id);
      dp = dp.filter((f) => f._id !== action.data._id);

      const nextPipeline =
        state.activePipeline && state.activePipeline._id === action.data._id
          ? dp.length > 0 && dp[idx]
            ? dp[idx]
            : dp[idx - 1]
          : state.activePipeline;

      return {
        ...state,
        test: state.test + 1,
        pipelines: [...dp],
        activePipeline: nextPipeline,
      };

    case events.MOVE_PROPERTY:
      const mpp = state.properties[action.data.from];
      const ufp = mpp.filter((p) => p._id !== action.data.property);
      let mp = { ...state.properties, [action.data.from]: ufp };
      global.updatePipeline = action.data.to;

      return {
        ...state,
        properties: mp,
        total: { ...state.total, [action.data.from]: mp.length },
      };

    case events.DELETE_PIPELINE_PROPERTY:
      const dpp = state.properties[action.data._id];
      let upp = {};
      if (dpp) {
        const ufp = dpp.filter((p) => p._id !== action.data.property);
        upp = { ...state.properties, [action.data._id]: ufp };
      } else {
        upp = state.properties;
      }
      return {
        ...state,
        properties: upp,
        total: { ...state.total, [action.data._id]: upp.length },
      };

    case events.ADD_PIPELINE_PROPERTY:
      const { property, pipeline } = action.data;
      const app = state.pipelines;
      const uapp = app.map((item) => {
        if (item._id === pipeline) {
          if (item.properties && item.properties.length > 0) {
            item.properties = [property, ...item.properties];
            item.total = item.total + 1;
          } else {
            item.properties = [property];
            item.total = 1;
          }
        }
        return item;
      });

      return {
        ...state,
        pipelines: [...uapp],
      };
    case events.ADD_PIPELINE_PROPERTY_NOTES:
      const appn = state.properties[action.data.pipeline];
      if (appn) {
        const idx = appn && appn.findIndex((p) => p._id === action.data._id);
        if (idx !== -1) {
          appn[idx].notes = action.data.notes;
        }
        return {
          ...state,
          properties: { ...state.properties, [action.data.pipeline]: appn },
        };
      }
      return state;
    case events.SET_PIPELINE_PROPERTIES:
      return {
        ...state,
        properties: {
          ...state.properties,
          [action.pipeline]: action.data ? action.data : [],
        },
        total: { ...state.total, [action.pipeline]: action.total },
      };
    default:
      return state;
  }
};

export default pipelines;
