//auth
export const AUTHENTICATE = "AUTHENTICATE";
export const LOGOUT = "LOGOUT";
export const RESET_MAPS = "RESET_MAPS";
export const SIGNUP = "SIGNUP";
export const SET_PROFILE = "SET_PROFILE";
export const RELOAD_HOME = "RELOAD_HOME";
export const SET_PROGRESS = "SET_PROGRRESS";
export const SET_FTNOTICE = "SET_FTNOTICE";
export const PROFILE_ROLE_UPDATE = "PROFILE_ROLE_UPDATE";
export const FETCH_LANDING_PAGE = "FETCH_LANDING_PAGE";
export const SET_LANDING_PAGE = "SET_LANDING_PAGE";
export const SET_REMINDER = "SET_REMINDER";
export const CREATE_REMINDER = "CREATE_REMINDER";
export const UPDATE_REMINDER = "UPDATE_REMINDER";
export const DELETE_REMINDER = "DELETE_REMINDER";

//plans
export const SET_PLANS = "SET_PLANS";
export const SET_ADDONS = "SET_ADDONS";
export const SUBSCRIBE = "SUBSCRIBE";
export const CANCEL = "CANCEL";

//campaigns
export const SET_CAMPAIGNS = "SET_CAMPAIGNS";
export const CREATE_CAMPAIGN = "CREATE_CAMPAIGN";
export const UPDATE_CAMPAIGN = "UPDATE_CAMPAIGN";
export const DELETE_CAMPAIGN = "DELETE_CAMPAIGN";
export const SET_CURRENT_CAMPAIGN = "SET_CURRENT_CAMPAIGN";

// Calls
export const SET_CONTACTS = "SET_CONTACTS";
export const SET_PURCHASED_CONTACTS = "SET_PURCHASED_CONTACTS";
export const SET_SEARCH = "SET_SEARCH";
export const SET_RECENT_LOGS = "SET_RECENT_LOGS";
export const SET_SMS_DATA = "SET_SMS_DATA";
export const SET_CHAT_DATA = "SET_CHAT_DATA";

//templates
export const SET_TEMPLATES = "SET_TEMPLATES";
export const CREATE_TEMPLATE = "CREATE_TEMPLATE";
export const UPDATE_TEMPLATE = "UPDATE_TEMPLATE";
export const DELETE_TEMPLATE = "DELETE_TEMPLATE";
export const SET_TEMPLATE_STYLES = "TEMPLATE_STYLES";
export const SET_TEMPLATE_MAILERS = "TEMPLATE_MAILERS";
export const SET_CURRENT_TEMPLATE = "SET_CURRENT_TEMPLATE";

//signatures
export const SET_SIGNATURES = "SET_SIGNATURES";
export const SET_CURRENT_SIGNATURE = "SET_CURRENT_SIGNATURE";
export const CREATE_SIGNATURE = "CREATE_SIGNATURE";
export const UPDATE_SIGNATURE = "UPDATE_SIGNATURE";
export const DELETE_SIGNATURE = "DELETE_SIGNATURE";

//pipelines
export const SET_PIPELINES = "SET_PIPELINES";
export const CREATE_PIPELINE = "CREATE_PIPELINE";
export const UPDATE_PIPELINE = "UPDATE_PIPELINE";
export const DELETE_PIPELINES = "DELETE_PIPELINES";
export const MOVE_PIPELINES = "MOVE_PIPELINES";
export const MOVE_PROPERTY = "MOVE_PROPERTY";
export const DELETE_PIPELINE_PROPERTY = "DELETE_PIPELINE_PROPERTY";
export const ADD_PIPELINE_PROPERTY_NOTES = "ADD_DELETE_PROPERTY_NOTES";
export const ADD_PIPELINE_PROPERTY = "ADD_DELETE_PROPERTY";
export const SET_ACTIVE_PIPELINE = "SET_ACTIVE_PIPELINE";
export const SET_PIPELINE_PROPERTIES = "SET_PIPELINE_PROPERTIES";
export const SELECT_PIPELINE = "SELECT_PIPELINE";

//lists
export const SET_LISTS = "SET_LISTS";
export const SET_USER_LISTS = "SET_USER_LISTS";
export const CREATE_LIST = "CREATE_LIST";
export const UPDATE_LIST = "UPDATE_LIST";
export const DELETE_LISTS = "DELETE_LISTS";
export const MOVE_LISTS = "MOVE_LISTS";
export const MOVE_LIST_PROPERTY = "MOVE_LIST_PROPERTY";
export const DELETE_LIST_PROPERTY = "DELETE_LIST_PROPERTY";
export const ADD_LIST_PROPERTY_NOTES = "ADD_LIST_PROPERTY_NOTES";
export const ADD_LIST_PROPERTY = "ADD_LIST_PROPERTY";
export const SET_ACTIVE_LIST = "SET_ACTIVE_LIST";
export const SET_LIST_PROPERTIES = "SET_LIST_PROPERTIES";
export const SELECT_PROPERTY = "SELECT_PROPERTY";
export const SET_LIST_USER = "SET_LIST_USER";
export const SET_LIST_CAMPAIGNS = "SET_LIST_CAMPAIGNS";
export const SET_ARCHIVES = "SET_ARCHIVES";

//properties
export const FILTER_LAST_UPDATED = "FILTER_LAST_UPDATED";
export const DELETE_PROPERTY = "DELETE_PROPERTY";
export const CREATE_PROPERTY = "CREATE_PROPERTY";
export const ADD_BULK_PROPERTY = "ADD_BULK_PROPERTY";
export const ADD_BULK_PROPERTIES = "ADD_BULK_PROPERTIES";
export const UPDATE_PROPERTY = "UPDATE_PROPERTY";
export const SET_PROPERTIES = "SET_PROPERTIES";
export const SET_PROPERTY_FILTER = "SET_PROPERTY_FILTER";
export const PROPERTY_RT_UPDATE = "PROPERTY_RT_UPDATE";
export const SET_FILTERED_PROPERTIES = "SET_FILTERED_PROPERTIES";
export const SET_SEARCHED_PROPERTIES = "SET_SEARCHED_PROPERTIES";
export const CLEAR_FILTERED_PROPERTIES = "CLEAR_FILTERED_PROPERTIES";
export const CLEAR_LAST_FETCHEDADDRESS = "CLEAR_LAST_FETCHEDADDRESS";
export const FETCHED_PROPERTY = "FETCHED PROPERTY";
export const SET_BACK = "SET_BACK";
export const MAP_PROPERTIES = "MAP_PROPERTIES";
export const STATE_PROPERTIES = "STATE_PROPERTIES";
export const ZIP_PROPERTIES = "ZIP_PROPERTIES";
export const LAST_LOCATION = "LAST_LOCATION";
export const UPDATE_STATE_PROPERTIES = "UPDATE_STATE_PROPERTIES";
export const UPDATE_ZIP_PROPERTIES = "UPDATE_ZIP_PROPERTIES";
export const ADD_MAP_PROPERTIES = "ADD_MAP_PROPERTIES";
export const SET_MAP_LOCATION = "SET_MAP_LOCATION";
export const FETCH_MAP_LOCATION = "FETCH_MAP_LOCATION";

export const GET_PROPERTY_ADDRESS = "GET_PROPERTY_ADDRESS";
export const GET_PROPERTY_OWNER = "GET_PROPERTY_OWNER";
export const UPLOAD_PROPERTY_PICTURE = "UPLOAD_PROPERTY_PICTURE";
export const SET_NEW_PROPERTY = "SET_NEW_PROPERTY";

export const SET_CLICKED = "SET_CLICKED";
export const SET_TIMER = "SET_TIMER";

export const SET_PROPERTIES_PHONES = "SET_PROPERTIES_PHONES";
export const SET_PROPERTIES_EMAILS = "SET_PROPERTIES_PHONES";
export const SET_PROPERTIES_ADDRESS = "SET_PROPERTIES_ADDRESS";
export const SET_PROPERTIES_TAGS = "SET_PROPERTIES_TAGS";
export const SET_PROPERTIES_NOTES = "SET_PROPERTIES_NOTES";

export const SET_STATUS_LIST = "SET_STATUS_LIST";
export const SET_TAGS = "SET_TAGS";
export const SMART_SEARCH = "SMART_SEARCH";

export const SET_NOTES_LIST = "SET_NOTES_LIST";

export const CREDIT_BALANCE = "CREDIT_BALANCE";

//buyers
export const SET_BUYER_LIST = "SET_BUYER_LIST";
export const CREATE_BUYER_LIST = "CREATE_BUYER_LIST";
export const UPDATE_BUYER_LIST = "UPDATE_BUYER_LIST";
export const DELETE_BUYER_LIST = "DELETE_BUYER_LIST";

export const SET_BUYER_CONTACTS = "SET_BUYER_CONTACTS";
export const FETCHED_CONTACT = "FETCHED_CONTACT";
export const CREATE_BUYER_CONTACT = "CREATE_BUYER_CONTACT";
export const UPDATE_BUYER_CONTACT = "UPDATE_BUYER_CONTACT";
export const DELETE_BUYER_CONTACT = "DELETE_BUYER_CONTACT";
export const ADD_BUYER_CONTACT_NOTES = "ADD_BUYER_CONTACT_NOTES";
export const SET_BUYER_EMAIL_SMTP = "SET_BUYER_EMAIL_SMTP";
export const UPDATE_EMAIL_SMTP_POSITION = "UPDATE_EMAIL_SMTP_POSITION";
export const FETCH_PURCHASED_NUMBERS = "FETCH_PURCHASED_NUMBERS";
export const SET_SMS_NUMBERS = "SET_SMS_NUMBERS";
export const FETCH_ALERT_NUMBERS = "FETCH_ALERT_NUMBERS";
export const CREATE_NUMBER_FOR_ALERTS = "CREATE_NUMBER_FOR_ALERTS";
export const FETCH_EMAIL_ACTIVITY = "FETCH_EMAIL_ACTIVITY";
export const FETCH_SMS_ACTIVITY = "FETCH_SMS_ACTIVITY";
export const DELETE_BUYER_NUMBER = "DELETE_BUYER_NUMBER";
export const CREATE_BUYER_EMAIL_SMTP = "CREATE_BUYER_EMAIL_SMTP";
export const DELETE_BUYER_SMTP = "DELETE_BUYER_SMTP";
export const REFRESH_CONTACTS = "REFRESH_CONTACTS";
export const SET_ACTIVE_BUYER_LIST = "SET_ACTIVE_BUYER_LIST";
export const SELECT_CONTACT = "SELECT_CONTACT";
export const SET_BUYER_LANDING_PAGE = "SET_BUYER_LANDING_PAGE";

//credits
export const SET_CREDITS = "SET_CREDITS";

//settings
export const SET_SETTINGS = "SET_SETTINGS";
export const SET_CREDIT_PRICE = "SET_CREDIT_PRICE";
export const CREATE_ORDER = "SET_CREATE_ORDER";
export const SET_SUBSCRIPTION = "SET_SUBSCRIPTION";
export const SET_INVOICES_LIST = "SET_INVOICES_LIST";

//activities
export const SET_ACTIVITIES = "SET_ACTIVITIES";

//issues
export const SET_ISSUES = "SET_ISSUES";
export const ADD_ISSUE = "ADD_ISSUE";

//faqs
export const SET_FAQS = "SET_FAQS";

//tasks
export const SET_TASKS = "SET_TASKS";
export const SET_DAY_TASKS = "SET_DAY_TASKS";

//team
export const SET_INVITED_MEMBERS = "SET_INVITED_MEMBERS";
export const SET_MY_MEMBERS = "SET_MY_MEMBERS";
export const ADD_INVITED_MEMBER = "ADD_INVITED_MEMBER";
export const DELETE_INVITED_MEMBER = "DELETE_INVITED_MEMEBER";

//driveroutes
export const SET_ROUTE = "SET_ROUTE";
export const SET_ROUTES = "SET_ROUTES";
export const SET_POINTS = "SET_POINTS";
export const SET_BGDATA = "SET_BGDATA";
export const SET_DRIVEROUTE = "SET_DRIVEROUTE";
export const DELETE_DRIVEROUTE = "DELETE_DRIVEROUTE";
export const SET_ANIMATED_POINTS = "SET_ANIMATED_POINTS";
export const SET_ANIMATED_RECONNECTION_POINTS =
  "SET_ANIMATED_RECONNECTION_POINTS";
export const SET_FOLLOWED_POINTS = "SET_FOLLOWED_POINTS";
export const SET_PHONE_HEADING = "SET_PHONE_HEADING";
export const RESET_ANIMATED_POINTS = "RESET_ANIMATED_POINTS";
export const DEL_FOLLOWED_POINTS = "DEL_FOLLOWED_POINTS";
export const DEL_ANIMATED_POINTS = "DEL_ANIMATED_POINTS";
export const SET_TEAM_DRIVERS = "SET_TEAM_DRIVERS";
export const SET_FOLLOWED_USER = "SET_FOLLOWED_USER";

//Saga Pipeline
export const ADD_PIPELINE_PROPERTY_SAGA = "ADD_PIPELINE_PROPERTY_SAGA";
export const REMOVE_PIPELINE_PROPERTY_SAGA = "REMOVE_PIPELINE_PROPERTY_SAGA";

//Saga List
export const ADD_LIST_PROPERTY_SAGA = "ADD_LIST_PROPERTY_SAGA";
export const REMOVE_LIST_PROPERTY_SAGA = "REMOVE_LIST_PROPERTY_SAGA";
