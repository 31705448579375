import {
  SET_SETTINGS,
  SET_CREDIT_PRICE,
  CREATE_ORDER,
  SET_SUBSCRIPTION,
  SET_INVOICES_LIST,
} from "../events";

const initialState = {
  settings: null,
  creditprice: null,
  order: null,
  subscription: null,
  invoices: null,
};
const settings = (state = initialState, action) => {
  switch (action.type) {
    case SET_SETTINGS:
      return {
        ...state,
        settings: action.data,
      };
    case SET_INVOICES_LIST:
      return {
        ...state,
        invoices: action.data,
      };
    case SET_CREDIT_PRICE:
      return {
        ...state,
        creditprice: action.data,
      };
    case CREATE_ORDER:
      return {
        ...state,
        order: action.data,
      };
    case SET_SUBSCRIPTION:
      return {
        ...state,
        subscription: action.data,
      };
    default:
      return state;
  }
};

export default settings;
