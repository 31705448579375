import * as events from "../events";

const usStates = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];

const initialState = {
  states: usStates,
  userSignatures: [],
  currentSignature: null,
  ownerTemplates: [],
  total: 0,
};

const signatures = (state = initialState, action) => {
  switch (action.type) {
    case events.SET_SIGNATURES:
      return {
        ...state,
        userSignatures: [...action.signatures],
        total: action.total ? action.total : 0,
      };
    case events.SET_CURRENT_SIGNATURE:
      return {
        ...state,
        currentSignature: action.data,
      };

    case events.CREATE_SIGNATURE:
      return {
        ...state,
        userSignatures: [...state.userSignatures, action.data],
      };

    case events.UPDATE_SIGNATURE:
      const signatureIndex = state.userSignatures.findIndex(
        (sign) => sign._id === action.data._id
      );

      const updatedUserSignatures = [...state.userSignatures];
      updatedUserSignatures[signatureIndex] = action.data;

      return {
        ...state,
        userSignatures: [...updatedUserSignatures],
      };

    case events.DELETE_SIGNATURE:
      return {
        ...state,
        userSignatures: state.userSignatures.filter(
          (signature) => signature._id !== action._id
        ),
      };
    default:
      return state;
  }
};

export default signatures;
