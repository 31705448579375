import React, { useState, useEffect, Suspense } from "react";
import { NativeBaseProvider, extendTheme, CloseIcon } from "native-base";
import io from "socket.io-client";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import addNotification from "react-push-notification";
import logo from "./assets/icon1.png";
import { CHAT_SERVER, getUser } from "./constants/Config";
import Splash from "./pages/public/Splash";
import PropFlipLogo from "./assets/PropflipIcon.png";

import store from "./store";
const Index = React.lazy(() => import("./pages/Index"));
const ShadowCalls = React.lazy(() => import("./pages/private/ShadowCalls"));
const LandingPage = React.lazy(() => import("./pages/public/LandingPage"));
const LandingPageInfo = React.lazy(() =>
  import("./pages/public/LandingPageInfo")
);

const App = () => {
  const user = getUser();
  const [support, setSck] = useState(null);
  const themeConfig = {
    useSystemColorMode: false,
    initialColorMode: "light",
    cols: {
      base: 5,
      lg: 3,
      xl: 4,
      md: 2,
      xs: 1,
      sm: 1,
    },
  };
  const customTheme = extendTheme({ config: themeConfig });

  const reminderAlert = (data) => {
    addNotification({
      title: "PropFlip",
      subtitle: "Reminder",
      message: data.msg,
      theme: "darkblue",
      native: true,
      // icon: logo,
      icon: PropFlipLogo,
    });
  };

  useEffect(() => {
    const connectSocket = async () => {
      const s1 = `${CHAT_SERVER}`;
      const socket = io(s1, {
        reconnection: true,
        transports: ["websocket"],
        allowUpgrades: false,
        pingTimeout: 30000,
      });

      socket
        .on("connect", () => {
          socket.emit("webapp:user", {
            id: user?._id,
            name: user?.firstname + " " + user?.lastname,
          });
          socket.on("reminder", (data) => {
            reminderAlert(data);
          });

          console.log("connected to support server");
          console.log("support server socket id reminder:", socket.id);
        })
        .on("reconnect", async (attempts) => {
          console.log("reconnected to support server");
        })
        .on("disconnect", async () => {
          console.log("disconnected support server");
        })
        .on("error", (error) => {
          console.log("support server error", error);
        });

      setSck(socket);
    };

    if (user) {
      connectSocket();
    }

    return () => {
      if (support) {
        support.disconnect();
      }
    };
  }, []);

  const CloseButton = ({ closeToast }) => (
    <i
      className="material-icons"
      onClick={closeToast}
      style={{
        paddingRight: "2px",
      }}
    >
      <CloseIcon
        size={3}
        style={{
          color: "white",
        }}
      />
    </i>
  );

  return (
    <NativeBaseProvider theme={customTheme}>
      <Suspense fallback={<Splash />}>
        <Provider store={store}>
          {user && <ShadowCalls />}
          <Router>
            <Routes>
              <Route exact path="/contacts/:id" element={<LandingPage />} />
              <Route
                exact
                path="/contacts/:id/:p_id"
                element={<LandingPageInfo />}
              />
              <Route
                path="/*"
                element={
                  <>
                    <Index />
                  </>
                }
              />
            </Routes>
          </Router>
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            toastStyle={{
              backgroundColor: "#382058",
              color: "white",
              borderRadius: "10px",
              fontFamily: "Poppins",
              fontWeight: "400",
              fontSize: "14px",
            }}
            closeButton={CloseButton}
          />
        </Provider>
      </Suspense>
    </NativeBaseProvider>
  );
};

export default App;
