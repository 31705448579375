/**
 *
 * Splash Screen
 * Author: Chandu, Likhitha
 * last modified on 20/sep/2023
 *
 */

import React from "react";
import { Box, Stack, useBreakpointValue } from "native-base";
import { vh } from "../../util";
import { BarLoader } from "react-spinners";
import PropFlipLogo from "../../components/svg/PropFlipLogo";

const Splash = () => {
  const sz = useBreakpointValue({
    base: "base",
    sm: "sm",
    lg: "lg",
    md: "md",
    xl: "xl",
  });

  return (
    <Box
      style={{
        flex: 1,
        minHeight: "100vh",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
      }}
    >
      <Stack
        style={{
          position: "absolute",
          top: "35%",
          left: "50%",
          width: sz === "sm" || sz === "base" ? "100px" : "150px",
          height: sz === "sm" || sz === "base" ? "100px" : "150px",
          transform: [{ translateX: "-50%", translateY: "-50%" }],
        }}
      >
        <PropFlipLogo stopColor="#7d2ae8" stopColor1="#7d2ae8" />
      </Stack>
      <Box
        width={sz === "sm" || sz === "base" ? "100px" : "10%"}
        alignItems="center"
        mt={sz === "sm" || sz === "base" ? vh(30) : vh(130)}
      >
        <BarLoader
          color="#7D2BE9"
          width={sz === "sm" || sz === "base" ? "70px" : "120px"}
        />
      </Box>
    </Box>
  );
};

export default Splash;
