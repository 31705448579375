import { takeEvery, call, put, fork } from "redux-saga/effects";
import * as actions from "../actions/lists";
import * as events from "../events";

function* addProperty(action) {
  try {
    const {data:property, list} = action.data;
    const result = yield call(actions.addPropertySaga, action.data);
    if (result.success) {
      // yield put(
      //   actions.addPropertySagaPut({
      //     list,
      //     property,
      //     statusCount: result.status
      //   })
      // );
    }
  } catch (error) {
    console.error(error);
  }
}

function* removeProperty(action) {
  try {
    const {property, list} = action.data;
    const result = yield call(actions.removePropertySaga, action.data);
    if (result) {
      // yield put(actions.removePropertySagaPut({list, property, statusCount: result.status}));
    }
  } catch (error) {
    console.error(error);
  }
}

function* listUpdate(action) {
  try {
    const result = yield call(actions.fetchListByIdSaga, action.data);
    if (result) {
      yield put(actions.updateListSagaPut(result));
    }
  } catch (error) {
    console.error(error);
  }
}

function* watchListPropertyAdd() {
  yield takeEvery(events.ADD_LIST_PROPERTY_SAGA, addProperty);
}

function* watchListPropertyRemove() {
  yield takeEvery(events.REMOVE_LIST_PROPERTY_SAGA, removeProperty);
}

function* watchListUpdate() {
  yield takeEvery(events.UPDATE_LIST, listUpdate);
}

const listSagas = [
  fork(watchListPropertyAdd),
  fork(watchListPropertyRemove),
  fork(watchListUpdate),
];

export default listSagas;
