import { SET_ISSUES, ADD_ISSUE } from "../events";

const initialState = {
  userIssues: [],
};

const issues = (state = initialState, action) => {
  switch (action.type) {
    case SET_ISSUES:
      return {
        userIssues: action.data.data ? action.data.data : [],
        total: action.data.count ? action.data.count : 0,
      };
    case ADD_ISSUE:
      let issues = state.userIssues;
      if (issues.length > 0) {
        const xi = issues.findIndex((i) => i._id === action.data._id);
        if (xi === -1) {
          issues = [action.data, ...issues];
        } else {
          issues[xi] = action.data;
        }
      } else {
        issues = [action.data];
      }

      return {
        userIssues: issues,
      };
    default:
      return state;
  }
};

export default issues;
