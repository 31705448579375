import * as events from "../events";
import _ from "lodash";
import moment from "moment-timezone";
// import activities from "./activities";
// import properties from "./properties";

const initialState = {
  lists: [],
  activities: [{ _id: 1, properties: [] }],
  activeList: null,
  listsFilter: {},
  s: null,
  properties: {},
  total: {},
  statusCount: {},
  selectProperty: false,
  user: null,
  defaultFilter: {
    fromDate: moment().subtract(1, "year").format("YYYY-MM-DD"),
    toDate: moment().format("YYYY-MM-DD"),
    filteredTags: [],
    filteredStatus: [],
    filteredBedrooms: [],
    filteredBathrooms: [],
    filteredFloors: [],
    filteredBuiltyear: [],
    filteredSqft: [],
    filteredAcre: [],
    filteredEv: [],
    sortBy: "tid",
    orderBy: "desc",
    isUserFilter: false,
  },
  test: 0,
  listcampaign: null,
};

const getUnique = (arr, comp) => {
  const unique = arr
    .map((e) => e[comp])

    // store the keys of the unique objects
    .map((e, i, final) => final.indexOf(e) === i && i)

    // eliminate the dead keys & store unique objects
    .filter((e) => arr[e])
    .map((e) => arr[e]);

  return unique;
};

const lists = (state = initialState, action) => {
  switch (action.type) {
    case events.CREATE_LIST:
      const activeList = state.activeList ? state.activeList : action.data;
      return {
        ...state,
        lists: [...state.lists, action.data],
        activeList,
      };
    case events.SELECT_PROPERTY:
      return {
        ...state,
        selectProperty: state.selectProperty ? false : true,
      };
    case events.SET_ACTIVE_LIST:
      return {
        ...state,
        activeList: action.data,
      };
    case events.SET_LIST_USER:
      return {
        ...state,
        user: action.data,
      };
    case events.SET_USER_LISTS:
      let ul = null;
      let userDefaultList = null;
      if (action.data) {
        ul = _.sortBy(action.data, "ord");
        userDefaultList = ul[0];
      }

      return {
        ...state,
        lists: ul,
        // activeList: userDefaultList,
      };
    case events.SET_LISTS:
      let ps = state.lists;

      if (ps.length > 0) {
        if (ps.length > 1) {
          ps = ps.map((p) => {
            const ad = action.data.find((ap) => ap._id === p._id);
            if (ad) {
              p = ad;
            }
            return p;
          });
        } else {
          ps = [...action.data];
        }
      } else {
        ps = action.data;
      }
      ps = _.sortBy(ps, "ord");
      const defaultList = !state.activeList
        ? ps.length > 0
          ? ps[0]
          : null
        : state.activeList;
      if (action.data.length === 1) {
        const edited = action.data[0];
        if (defaultList.campaign) {
          if (defaultList.campaign !== edited.campaign) {
            defaultList.campaign = edited.campaign;
          }
        }
      }
      return {
        ...state,
        lists: ps,
        activeList: defaultList,
      };
    case events.MOVE_LISTS:
      let xp = state.lists;
      xp = _.orderBy(xp, "ord", "asc");
      let ft = action.data;

      const x = xp.findIndex((p) => p.ord === ft.from);
      const y = xp.findIndex((p) => p.ord === ft.to);
      const xord = xp[x].ord;
      const yord = xp[y].ord;
      xp[x].ord = yord;
      xp[y].ord = xord;
      xp = _.orderBy(xp, "ord", "asc");
      return {
        ...state,
        test: state.test + 1,
        lists: xp,
      };
    case events.DELETE_LISTS:
      let dp = state.lists;
      const idx = dp.findIndex((f) => f._id === action.data._id);
      dp = dp.filter((f) => f._id !== action.data._id);

      const nextList =
        state.activeList && state.activeList._id === action.data._id
          ? dp.length > 0 && dp[idx]
            ? dp[idx]
            : dp[idx - 1]
          : state.activeList;

      return {
        ...state,
        test: state.test + 1,
        lists: [...dp],
        activeList: nextList,
      };
    case events.MOVE_PROPERTY:
      const mpp = state.lists;
      let mp = {};
      let up = mpp.map((item) => {
        if (item._id === action.data.from) {
          mp = item.properties.find((p) => p._id === action.data.property);
          item.properties = item.properties.filter(
            (p) => p._id !== action.data.property
          );
        }
        return item;
      });

      up = up.map((item) => {
        if (item._id === action.data.to && Object.keys(mp).length > 0) {
          item.properties = getUnique([...item.properties, mp], "_id");
        }
        return item;
      });
      return {
        ...state,
        test: state.test + 1,
        lists: [...up],
      };
    case events.DELETE_LIST_PROPERTY:
      const {
        property: delProp,
        list: delList,
        statusCount: delListStatus,
      } = action.data;
      let listDelProps = state.properties[delList] || [];
      const listDelTotal = state.total[delList] || 0;
      const delStatusCount = delListStatus || [];
      listDelProps = listDelProps.filter((p) => {
        return p._id !== delProp;
      });

      return {
        ...state,
        properties: {
          ...state.properties,
          [delList]: [...listDelProps],
        },
        total: { ...state.total, [delList]: listDelTotal - 1 },
        statusCount: { ...state.statusCount, [delList]: delStatusCount },
      };
    case events.ADD_LIST_PROPERTY:
      const {
        property: listProp,
        list: addList,
        statusCount: listStatus,
      } = action.data;
      const listProps = state.properties[addList] || [];
      const listTotal = state.total[addList] || 0;
      const statusCount = listStatus || [];

      return {
        ...state,
        properties: {
          ...state.properties,
          [addList]: [...listProps, listProp],
        },
        total: { ...state.total, [addList]: listTotal + 1 },
        statusCount: { ...state.statusCount, [addList]: statusCount },
      };
    case events.SET_LIST_PROPERTIES:
      return {
        ...state,
        properties: {
          ...state.properties,
          [action.list]: action.data ? action.data : [],
        },
        total: { ...state.total, [action.list]: action.total },
        statusCount: {
          ...state.statusCount,
          [action.list]: action.statusCount,
        },
      };

    case events.ADD_LIST_PROPERTY_NOTES:
      const npp = [...state.lists];

      const ppn = [...npp].map((p) => {
        const idx =
          p.properties &&
          p.properties.findIndex((p) => p._id === action.data._id);
        if (idx !== -1) {
          p.properties[idx].notes = action.data.notes;
        }
        return p;
      });

      return {
        ...state,
        lines: [...ppn],
      };
    case events.FILTER_LAST_UPDATED:
      return {
        ...state,
        flu: action.data,
      };
    case events.SET_LIST_CAMPAIGNS:
      return {
        ...state,
        listcampaign: action.campaigns,
      };
    default:
      return state;
  }
};

export default lists;
