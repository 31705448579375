import { configureStore, combineReducers } from "@reduxjs/toolkit";
import ReduxThunk from "redux-thunk";
import createSagaMiddleware from "redux-saga";
import limits from "./reducers/limits";
import auth from "./reducers/auth";
import properties from "./reducers/properties";
import lists from "./reducers/lists";
import pipelines from "./reducers/pipelines";
import buyer from "./reducers/buyers";
import campaigns from "./reducers/campaigns";
import signatures from "./reducers/signatures";
import rootSaga from "./sagas";
import templates from "./reducers/templates";
import maps from "./reducers/maps";
import driveroute from "./reducers/driveroute";
import plan from "./reducers/plan";
import settings from "./reducers/settings";
import credits from "./reducers/credits";
import issues from "./reducers/issues";
import tasks from "./reducers/tasks";

const sagaMiddleware = createSagaMiddleware();

const reducer = combineReducers({
  limits,
  auth,
  properties,
  pipelines,
  lists,
  buyer,
  campaigns,
  signatures,
  templates,
  maps,
  driveroute,
  plan,
  settings,
  credits,
  issues,
  tasks,
});

const store = configureStore({
  reducer,
  middleware: [ReduxThunk, sagaMiddleware],
});

sagaMiddleware.run(rootSaga);

export default store;
