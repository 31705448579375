import moment from "moment";
import * as events from "../events";
const initialState = {
  stateProperties: [],
  zipProperties: [],
  lastFetchedOwner: null,
  lastLocation: null,
  mapProperties: [],
  mktProperties: [],
  lastupdated: null,
  mostviewed: null,
  history: null,
};

const getUnique = (arr, comp) => {
  const unique = arr
    .map((e) => e[comp])

    // store the keys of the unique objects
    .map((e, i, final) => final.indexOf(e) === i && i)

    // eliminate the dead keys & store unique objects
    .filter((e) => arr[e])
    .map((e) => arr[e]);

  return unique;
};

const maps = (state = initialState, action) => {
  switch (action.type) {
    case events.RESET_MAPS:
      return initialState;
    case events.MAP_PROPERTIES:
      return {
        ...state,
        mapProperties: action.data,
        mktProperties: action.mdata,
      };
    case events.ADD_MAP_PROPERTIES:
      const mps = [];
      if (action.data.length > 0) {
        action.data.map((a) => {
          mps.push({
            _id: a._id,
            tid: a.tid,
            name: a.name,
            coord: a.coords,
          });
          return a;
        });
      }

      const mp = state.mapProperties ? state.mapProperties : [];
      const uniqueMapProperties = getUnique([...mp, ...mps], "tid");

      return {
        ...state,
        mapProperties: uniqueMapProperties,
      };
    case events.LAST_LOCATION:
      return {
        ...state,
        lastLocation: action.data,
      };
    case events.STATE_PROPERTIES:
      return {
        ...state,
        stateProperties: [...action.data],
      };
    case events.UPDATE_STATE_PROPERTIES:
      let sp = state.stateProperties;
      if (action.data.length > 0) {
        action.data.map((d) => {
          const idx = sp.findIndex((p) => p.state === d.state);
          if (idx !== -1) {
            sp[idx] = d;
          } else {
            sp.push(d);
          }
          return d;
        });
      }

      return {
        ...state,
        lastupdated: moment().valueOf(),
        stateProperties: [...sp],
      };
    case events.UPDATE_ZIP_PROPERTIES:
      let zp = state.zipProperties;
      if (action.data.length > 0) {
        action.data.map((d) => {
          const idx = zp.findIndex((p) => p.zip === d.zip);
          if (idx !== -1) {
            zp[idx] = d;
          } else {
            zp.push(d);
          }
          return d;
        });
      }
      return {
        ...state,
        zipProperties: [...zp],
      };
    case events.ZIP_PROPERTIES:
      return {
        ...state,
        zipProperties: action.data,
      };
    case events.SET_MAP_LOCATION:
      return {
        ...state,
        history: action.history ? action.history : null,
      };
    case events.FETCH_MAP_LOCATION:
      return {
        ...state,
        mostviewed: action.mostviewed ? action.mostviewed : null,
        history: action.history ? action.history : null,
      };
    default:
      return state;
  }
};

export default maps;
