import * as React from "react";
import Svg, { LinearGradient, Stop, Path } from "react-native-svg";
const PropFlipLogo = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      enableBackground: "new 0 0 72 88",
    }}
    viewBox="0 0 72 88"
    {...props}
  >
    <LinearGradient
      id="a"
      x1={61.742}
      x2={26.011}
      y1={125.067}
      y2={32.53}
      gradientUnits="userSpaceOnUse"
    >
      <Stop
        offset={0}
        style={{
          stopColor: props.stopColor || "#00c4cc",
        }}
      />
      <Stop
        offset={1}
        style={{
          stopColor: props.stopColor1 || "#7d2ae8",
        }}
      />
    </LinearGradient>
    <Path
      d="m48.6 10.1-4.8-3.8L36 0 0 28.8v30.4h15.6v15.3h6V59.2h24.5c5.9 0 10.9-3.9 12.6-9.3.5-1.3.7-2.5.7-3.9 0-3.7-1.5-7-4-9.3-2.3-2.4-5.7-3.9-9.3-3.9H15.6v14.4h6v-8.4h24.5c2 0 3.8.8 5.1 2.1 1.3 1.3 2.2 3.1 2.2 5.1 0 2-.8 3.8-2.2 5.1-1.3 1.3-3.1 2.1-5.1 2.1H6V31.7l30-24 3 2.4 4.8 3.8L66 31.7V82H0v6h72V28.8L48.6 10.1z"
      style={{
        fill: "url(#a)",
      }}
    />
  </Svg>
);
export default PropFlipLogo;
