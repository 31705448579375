import {
  SET_ROUTES,
  SET_POINTS,
  SET_DRIVEROUTE,
  DELETE_DRIVEROUTE,
  SET_ANIMATED_POINTS,
  SET_ANIMATED_RECONNECTION_POINTS,
  RESET_ANIMATED_POINTS,
  SET_FOLLOWED_POINTS,
  DEL_FOLLOWED_POINTS,
  DEL_ANIMATED_POINTS,
  SET_PHONE_HEADING,
  SET_TEAM_DRIVERS,
  SET_FOLLOWED_USER,
} from "../events";

const initialState = {
  teamdrivers: [],
  drivers: {},
  route: [],
  routes: [],
  bg: [],
  total: 0,
  points: [],
  followedpoints: [],
  followeduser: null,
  heading: 0,
  speed: 0,
  lastpoint: {},
};

const driveroute = (state = initialState, action) => {
  const activeDrivers = state.drivers;
  switch (action.type) {
    case SET_TEAM_DRIVERS:
      return {
        ...state,
        teamdrivers: action.data,
      };
    case SET_FOLLOWED_USER:
      let fup =
        state.drivers &&
        state.drivers[action.data] &&
        state.drivers[action.data].points
          ? state.drivers[action.data].points.map((p) => p.location)
          : [];
      return {
        ...state,
        followeduser: action.data,
        followedpoints: fup,
        lastpoint: fup[fup.length - 1],
      };
    case SET_ANIMATED_POINTS:
      return {
        ...state,
        // points: [...state.points, action.point],
        points: [action.point, ...state.points],
      };
    case SET_ANIMATED_RECONNECTION_POINTS:
      // const rp = action.points.map((p) => ({
      //   ...p.location,
      //   heading: p.heading,
      // }));
      return {
        ...state,
        points: [...state.points, ...action.points],
      };
    case SET_FOLLOWED_POINTS:
      let fp = action.point;
      if (action.point.location) {
        fp = action.point.location;
      }
      return {
        ...state,
        followedpoints: [...state.followedpoints, fp],
      };
    case SET_PHONE_HEADING:
      return {
        ...state,
        heading: action.data.heading || 0,
        speed: action.data.speed || 0,
      };
    case RESET_ANIMATED_POINTS:
      const user = (global.user && global.user._id) || null;
      const resetPoints =
        (user &&
          state.drivers &&
          state.drivers.length &&
          state.drivers[user]) ||
        [];
      return {
        ...state,
        points: resetPoints,
      };
    case DEL_ANIMATED_POINTS:
      return {
        ...state,
        points: [],
      };
    case DEL_FOLLOWED_POINTS:
      return {
        ...state,
        followedpoints: [],
      };
    case SET_DRIVEROUTE:
      const drivedata = action.data;
      let drivers = state.drivers;
      if (!drivers[drivedata.user]) {
        const appdriver = global.user && global.user._id === drivedata.user;
        if (appdriver) {
          global.milesdriven = drivedata.distance;
        }
        drivers[drivedata.user] = {
          route: drivedata.route,
          points: drivedata.points,
          distance: drivedata.distance,
          currPos: drivedata.points
            ? drivedata.points[drivedata.points.length - 1]
            : null,
          color: drivedata.color,
          user: drivedata.user,
          team: drivedata.team,
          name: drivedata.name,
          appdriver,
        };
      } else {
        const appdriver = global.user && global.user._id === drivedata.user;
        if (appdriver) {
          global.milesdriven = drivedata.distance;
        }

        if (drivers && drivers[drivedata.user]) {
          drivers[drivedata.user].distance = drivedata.distance;
          drivers[drivedata.user].points.push(...drivedata.points);
          drivers[drivedata.user].currPos = drivedata.points
            ? drivedata.points[drivedata.points.length - 1]
            : null;
        }
      }
      const sortObject = (o) =>
        Object.keys(o)
          .sort()
          .reduce((r, k) => ((r[k] = o[k]), r) || {});
      drivers = sortObject(drivers);
      return { ...state, drivers };

    case DELETE_DRIVEROUTE:
      if (activeDrivers[action.data.user]) {
        delete activeDrivers[action.data.user];
      }

      return {
        ...state,
        drivers: activeDrivers,
        points:
          action.data.user === global.user && global.user._id
            ? []
            : state.points,
      };
    case SET_POINTS:
      if (activeDrivers[action.data.user]) {
        activeDrivers[action.data.user] = action.data;
        activeDrivers[action.data.user].currPos = action.data.points
          ? action.data.points[action.data.points.length - 1]
          : null;
      }
      return {
        ...state,
        drivers: activeDrivers,
        followedpoints: action.data.points.map((p) => p.location),
      };

    case SET_ROUTES:
      return {
        ...state,
        total: action.total,
        routes: action.data,
      };
    default:
      return state;
  }
};

export default driveroute;
