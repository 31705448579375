import { LIMITS } from "../actions/limits";

const initialState = {
  ipslimits: {},
  // hosts: null,
  lastupdated: new Date().valueOf(),
};

const limits = (state = initialState, action) => {
  switch (action.type) {
    case LIMITS:
      return {
        ipslimits: { ...state.ipslimits, ...action.limits },
        lastupdated: new Date().valueOf(),
      };
    default:
      return state;
  }
};

export default limits;
